<template>
  <section class="section-main">
    <div class="section-main__content" v-if="isLoading">
      <inline-svg
        :src="require('../../assets/icon/svg/loading.svg')"
        class="loading"
      />
    </div>
    <div class="section-main__content" v-else>
      <inline-svg :src="require('../../assets/icon/svg/warning.svg')" />
      <div class="section-main__title">
        <h1>
          充電槍{{
            this.status ===
            (this.connectorStatus.Preparing ||
              this.connectorStatus.Charging ||
              this.connectorStatus.Finishing)
              ? "正在使用中"
              : "目前無法提供服務"
          }}
        </h1>
      </div>
      <p>請使用其它充電裝置，重新掃描 QRcode</p>
    </div>
    <div class="section-main__action">
      <button
        class="button is-fullwidth is-fill is-rounded"
        @click="handleClick()"
        type="button"
      >
        回到充電槍配對
      </button>
    </div>
  </section>
</template>
<script>
import connectorAPI from "../../apis/connector";
export default {
  name: "BeingUsedMessage",
  data() {
    return {
      connectorStatus: {
        Available: 1,
        Unavailable: 2,
        Preparing: 3,
        Charging: 4,
        SuspendedEVSE: 5,
        SuspendedEV: 6,
        Finishing: 7,
        Faulted: 8,
        Remove: 9,
        Reserved: 10,
        Offline: 11,
        UnKnow: 12,
      },
      status: 0,
      isLoading: true,
    };
  },
  async mounted() {
    const { connectorId } = this.$route.params;
    this.checkConnectorState(connectorId);
  },
  methods: {
    async checkConnectorState(connectorid) {
      try {
        const { data } = await connectorAPI.connector.getStatus({
          connectorid,
        });
        const { status } = data;
        this.status = status;
        if (status === this.connectorStatus.Available) {
          this.$router.push({
            name: "Connector",
            query: {
              connectorId: this.$route.params.connectorId,
              stationId: this.$route.params.stationId,
            },
          });
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error.message);
      }
    },
    handleClick() {
      this.$router.push({
        name: "ConnectorSearch",
      });
    },
  },
};
</script>
